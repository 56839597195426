<template>
  <div class="box">
    <div class="has-margin-bottom">
      <span class="has-text-weight-bold">Prices</span>
      <span class="has-margin-left">
        <a @click="setShowInfo">
          <span class="icon is-small">
            <font-awesome-icon :icon="['fas', 'info-circle']" />
          </span>
        </a>
      </span>
    </div>

    <div v-if="showInfo">
      <article class="message is-info is-small">
        <div class="message-body"></div>
      </article>
    </div>

    <div v-if="mShowBox && !showInfo">
      <table class="table is-fullwidth is-narrow">
        <tbody>
          <tr>
            <td>
              <div
                v-for="priceCalculationType in priceCalculationTypes"
                :key="priceCalculationType.id"
              >
                <label class="is-size-7">
                  <input
                    type="radio"
                    name="priceCalculationType"
                    @change="priceCalculationTypeClicked"
                    v-model="yieldSettingsPlayground.CalculationType"
                    :value="priceCalculationType.type"
                    :checked="
                      priceCalculationType.type ===
                      yieldSettingsPlayground.CalculationType
                    "
                  />
                  {{ priceCalculationType.name }}
                </label>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="is-divider"></div>

      <div v-if="yieldSettingsPlayground.CalculationType !== 'PerHourPerSeat'">
        <div class="has-margin-bottom">
          <span class="has-text-weight-bold">Nr of hours per day part</span>
        </div>

        <div class="select is-small">
          <select
            v-model="yieldSettingsPlayground.HoursPerDayPart"
            @change="setPriceParts"
          >
            <option
              v-for="dayPart in dayParts"
              :key="dayPart"
              :value="dayPart"
              :selected="dayPart === yieldSettingsPlayground.HoursPerDayPart"
            >
              {{ dayPart }}
            </option>
          </select>
        </div>

        <div class="is-divider"></div>
      </div>

      <table class="table is-narrow is-striped">
        <tbody>
          <tr
            v-for="(pricePart, index) in yieldSettingsPlayground.PriceParts"
            :key="index"
          >
            <td width="125">
              <span
                v-if="
                  yieldSettingsPlayground.CalculationType === 'PerHourPerSeat'
                "
                class="is-size-7"
                >hour
              </span>
              <span class="is-size-7">{{ pricePart.Part }}</span>
              <span
                v-if="
                  yieldSettingsPlayground.CalculationType !==
                    'PerHourPerSeat' && pricePart.Part === 1
                "
                class="is-size-7"
              >
                day part</span
              >
              <span
                v-if="
                  yieldSettingsPlayground.CalculationType !==
                    'PerHourPerSeat' && pricePart.Part > 1
                "
                class="is-size-7"
              >
                day parts</span
              >
            </td>
            <td width="100">
              <input
                type="text"
                v-model.number="pricePart.Price"
                @change="priceChanged"
                class="input is-small"
              />
            </td>
            <td>
              <a @click="removePricePart(index)" class="is-size-7">
                <span class="icon has-text-danger">
                  <font-awesome-icon :icon="['fas', 'times']" />
                </span>
              </a>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>&nbsp;</td>
            <td class="has-text-right">
              <a @click="addPricePart" class="button is-small is-size-7">
                <span class="icon has-text-success">
                  <font-awesome-icon :icon="['fas', 'plus']" />
                </span>
                <span>Add</span>
              </a>
            </td>
            <td>&nbsp;</td>
          </tr>
        </tfoot>
      </table>
    </div>

    <div class="has-text-centered has-margin-top">
      <a @click="mShowBox = !mShowBox" class="button is-small is-rounded">
        <span v-if="mShowBox" class="icon is-small">
          <font-awesome-icon :icon="['fas', 'chevron-up']" />
        </span>
        <span v-if="!mShowBox" class="icon is-small">
          <font-awesome-icon :icon="['fas', 'chevron-down']" />
        </span>
      </a>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  props: {
    showBox: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      dayParts: [1, 2, 3, 4, 5, 6, 12],
      mShowBox: true,
      priceCalculationTypes: [],
      showInfo: false,
    }
  },

  computed: {
    ...mapState('yieldStore', ['yieldSettingsPlayground']),
  },

  watch: {
    showBox: {
      immediate: true,
      handler: function (newVal) {
        this.mShowBox = newVal
      },
    },
  },

  created() {
    this.priceCalculationTypes.push({
      id: 0,
      type: 'PerHourPerSeat',
      name: 'Price per seat per hour',
    })
    this.priceCalculationTypes.push({
      id: 1,
      type: 'PerDayPartOnly',
      name: 'Price per day part',
    })
    this.priceCalculationTypes.push({
      id: 2,
      type: 'PerDayPartPerPerson',
      name: 'Price per day part per person',
    })
  },

  methods: {
    ...mapMutations('yieldStore', ['setYieldSettingsPlayground']),

    getChannelPrices() {},

    setShowInfo() {
      this.showInfo = !this.showInfo
      if (this.showInfo) {
        this.mShowBox = true
      }
    },

    priceChanged() {
      this.updateYieldPlayground()
    },

    updateYieldPlayground() {
      this.setYieldSettingsPlayground(this.yieldSettingsPlayground)
      this.$emit('yieldSettingsUpdated')
    },

    getCalculationTypeId(type) {
      let calcIndex = this.priceCalculationTypes.findIndex(
        (pc) => pc.type === type
      )
      return this.priceCalculationTypes[calcIndex].id
    },

    priceCalculationTypeClicked() {
      let self = this
      if (self.yieldSettingsPlayground.CalculationType === 'PerHourPerSeat') {
        self.yieldSettingsPlayground.HoursPerDayPart = 1
        self.setPriceParts()
      }

      self.updateYieldPlayground()
    },

    setPriceParts() {
      let self = this

      self.yieldSettingsPlayground.PriceParts = []
      let nrOfParts = 12 / self.yieldSettingsPlayground.HoursPerDayPart

      for (let i = 0; i < nrOfParts; i++) {
        self.yieldSettingsPlayground.PriceParts.push({
          ChannelId: self.yieldSettingsPlayground.ChannelId,
          LocationId: self.yieldSettingsPlayground.LocationId,
          MeetingtypeId: self.yieldSettingsPlayground.MeetingtypeId,
          SpaceId: 0,
          Part: i + 1,
          Price: 0,
        })
      }
    },

    addPricePart() {
      let self = this
      let existingPriceParts = self.yieldSettingsPlayground.PriceParts.length
      self.yieldSettingsPlayground.PriceParts.push({
        ChannelId: self.yieldSettingsPlayground.ChannelId,
        LocationId: self.yieldSettingsPlayground.LocationId,
        MeetingtypeId: self.yieldSettingsPlayground.MeetingtypeId,
        SpaceId: 0,
        Part: existingPriceParts + 1,
        Price: 0,
      })

      this.updateYieldPlayground()
    },

    removePricePart(index) {
      let self = this

      self.yieldSettingsPlayground.PriceParts.splice(index, 1)

      for (let i = 0; i < self.yieldSettingsPlayground.PriceParts.length; i++) {
        self.yieldSettingsPlayground.PriceParts[i].Part = i + 1
      }

      this.updateYieldPlayground()
    },
  },
}
</script>
